<!-- <div class="filter-ctr">
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col">Company</th>
        <th scope="col">First name</th>
        <th scope="col">Last name</th>
        <th scope="col">Email</th>
        <th scope="col">Start date</th>
        <th scope="col">{{ label === 'inactive' ? 'Access Type' : 'End date' }}</th>
        <th scope="col" *ngIf="label !== 'inactive'">Term(Month)</th>
        <th scope="col" *ngIf="label !== 'inactive'">Days Remaining</th>
        <th scope="col" class="clickable">
          <span title="Total Video Devices">D</span>
          <span title="Total Huddle Rooms">/H</span>
          <span title="Total VIP Users">/U</span>
          <span title="Total Audio devices">/A</span>
          <span title="Total IoT devies">/I</span>
          <span title="Total IoT Spaces">/S</span>
        </th>
        <th scope="col">Sites</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of newData; trackby item?.company_id">
        <td>{{ item.company_name }}</td>
        <td>{{ item.first_name }}</td>
        <td>{{ item.last_name }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.start_date }}</td>
        <td>{{ label === 'inactive' ? item.status : item.end_date }}</td>
        <td *ngIf="label !== 'inactive'">{{ item.term }}</td>
        <td *ngIf="label !== 'inactive'">{{ item.days_remaining }}</td>
        <td>{{ checkIfDomotzIsEnabled(item.DHUAI, item) }}</td>
        <td>{{ item.sites }}</td>
      </tr>
    </tbody>
  </table>
</div> -->

<div class="filter-ctr">
  <div *ngIf="!dataSource" class="filter-ctr__loader">
    <img src="{{ loader }}" alt="" />
  </div>
  <div *ngIf="dataSource">
    <div class="clearfix">
      <div class="filter-ctr__search">
        <i class="fa fa-search"></i>
        <input (keyup)="searchData($event.target.value)" placeholder="Search..." />
      </div>
    </div>
    <div class="filter-ctr__table" *ngIf="dataSource?.filteredData?.length > 0">
      <table
        class="w-100"
        mat-table
        [dataSource]="dataSource"
        matSort
      >
        <ng-container matColumnDef="company_name">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let item">
            {{ item.company_name }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <b>Total: {{ dataSource?.filteredData?.length }}</b>
          </td>
        </ng-container>

        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef>First Name</th>
          <td mat-cell *matCellDef="let item">
            {{ item.first_name }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef>Last Name</th>
          <td mat-cell *matCellDef="let item">
            {{ item.last_name }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let item">
            {{ item.email }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="start_date">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let item">
            {{ item.start_date }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="access_type_or_end_date">
          <th mat-header-cell *matHeaderCellDef>
            {{ label === 'inactive' ? 'Access Type' : 'End date' }}
          </th>
          <td mat-cell *matCellDef="let item">
            {{ label === 'inactive' ? item.status : item.end_date }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container *ngIf="label !== 'inactive'">
          <ng-container matColumnDef="term">
            <th mat-header-cell *matHeaderCellDef>Term(Month)</th>
            <td mat-cell *matCellDef="let item">
              {{ item.term }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="days_remaining">
            <th mat-header-cell *matHeaderCellDef>Days Remaining</th>
            <td mat-cell *matCellDef="let item">
              {{ item.days_remaining }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="dhuai">
          <th mat-header-cell *matHeaderCellDef>
            <span title="Total Video Devices">D</span>
            <span title="Total Huddle Rooms">/H</span>
            <span title="Total VIP Users">/U</span>
            <span title="Total Audio devices">/A</span>
            <span title="Total IoT devies">/I</span>
            <span title="Total IoT Spaces">/S</span>
          </th>
          <td mat-cell *matCellDef="let item">
            {{ checkIfDomotzIsEnabled(item.DHUAI, item) }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <b
              >{{ videoTotal }}/{{ huddleTotal }}/{{ userTotal }}/{{ audioTotal }}/{{ iotTotal }}/{{
                spacesTotal
              }}</b
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="sites">
          <th mat-header-cell *matHeaderCellDef>Sites</th>
          <td mat-cell *matCellDef="let item">
            {{ item.sites }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <b>{{ siteTotal }}</b>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
      </table>
    </div>
    <p class="text-center mt-3" *ngIf="dataSource?.filteredData?.length <= 0">
      Sorry, no entry found
    </p>
    <div class="filter-ctr__lower-section" *ngIf="dataSource?.data?.length > 0">
      <button (click)="openLink()" mat-raised-button color="primary">Export Report</button>
    </div>
  </div>
</div>
