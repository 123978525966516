<div class="report-container">
  <div class="report-left-section">
    <h6 (click)="setHighlight(1)" [class.report-btn-active]="highlightNum == 1">Trials</h6>
    <h6 (click)="setHighlight(2)" [class.report-btn-active]="highlightNum == 2">Subscriptions</h6>
    <h6 (click)="setHighlight(3)" [class.report-btn-active]="highlightNum == 3">Inactive</h6>
  </div>

  <div class="report-right-section">
    <div *ngIf="highlightNum === 1">
      <app-filter-table
        [tableSettings]="trailTableSettings"
        [tableSeedData]="reportTrialData"
        [label]="'trial'"
      >
      </app-filter-table>
      <!-- <div class="report-table-header-below">
        <span>{{ reportTrialData.length }}</span>
        <span style="margin-left: 88%;"
          ><b
            >{{ videoTotal }}/{{ huddleTotal }}/{{ userTotal }}/{{ audioTotal }}/{{ iotTotal }}/{{iotSpaceTotal}}</b
          ></span
        >
        <span style="margin-left: 5%;">{{ siteTotal }}</span>
      </div> -->
    </div>

    <div *ngIf="highlightNum === 2">
      <app-filter-table
        [tableSettings]="subTableSettings"
        [tableSeedData]="reportSubscriptionsData"
        [label]="'subscription'"
      >
      </app-filter-table>
      <!-- <div class="report-table-header-below">
        <span
          ><b>{{ reportSubscriptionsData.length }}</b></span
        >
        <span style="margin-left: 89%;"
          ><b
            >{{ videoTotal }}/{{ huddleTotal }}/{{ userTotal }}/{{ audioTotal }}/{{ iotTotal }}/{{iotSpaceTotal}}</b
          ></span
        >
        <span style="margin-left: 4%;"
          ><b>{{ siteTotal }}</b></span
        >
      </div> -->
    </div>

    <div *ngIf="highlightNum === 3">
      <app-filter-table
        [tableSettings]="inActiveTableSettings"
        [tableSeedData]="reportInactiveData"
        [label]="'inactive'"
      >
      </app-filter-table>
      <!-- <div class="report-table-header-below">
        <span
          ><b>{{ reportInactiveData.length }}</b></span
        >
        <span style="margin-left: 88%;"
          ><b
            >{{ videoTotal }}/{{ huddleTotal }}/{{ userTotal }}/{{ audioTotal }}/{{ iotTotal }}/{{iotSpaceTotal}}</b
          ></span
        >
        <span style="margin-left: 3.8%;"
          ><b>{{ siteTotal }}</b></span
        >
      </div> -->
    </div>
    <!-- <div class="report-lower-section">
      <span>Total</span>
      <button (click)="openLink(highlightNum)" mat-raised-button color="primary">
        Export Report
      </button>
    </div> -->
  </div>
</div>

<!--  -->
