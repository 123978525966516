import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

// import { LocalDataSource } from 'ng2-smart-table';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { UserInfoService } from '../../../app/services/user-info.service';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss'],
})
export class FilterTableComponent implements OnInit, OnChanges {
  @Input() tableSettings: Array<Object> = [];
  @Input() tableSeedData: Array<Object> = [];
  @Input() label: any;
  dataSource: any = null;
  displayedColumns: any[] = [
    'company_name',
    'first_name',
    'last_name',
    'email',
    'start_date',
    'access_type_or_end_date',
    'term',
    'days_remaining',
    'dhuai',
    'sites',
  ];
  videoTotal = 0;
  audioTotal = 0;
  iotTotal = 0;
  huddleTotal = 0;
  userTotal = 0;
  siteTotal = 0;
  spacesTotal = 0;
  isDomotzAllowed: boolean = environment?.config?.domotzIntegration
  loader: String = environment.config.dynamicImage.loader;
  // @Input() domotzEnabled: any;

  constructor(private route: ActivatedRoute, private Auth: AuthService, private router: Router, private userInfo: UserInfoService) {}

  ngOnInit() {
    // remove term and days_remaining
    if(this.label === 'inactive')
      this.displayedColumns.splice(6,2)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableSeedData && this.tableSeedData) {
      this.determineIfDomotzIsEnabledByCompanyId(this.tableSeedData);
    }
  }

  determineIfDomotzIsEnabledByCompanyId = async (obj) => {
    if(obj?.length === 0 || !this.isDomotzAllowed)
      this.dataSource = new MatTableDataSource(obj)
    else if(this.isDomotzAllowed) {
      const newArray = await Promise.all(
        obj.map(async (e) => {
          return {
            ...e,
            domotzEnabled: await this.getDomotzAccountByCompanyId(e.company_id),
          };
        })
      );

      // this.newData = newArray;
      this.dataSource = new MatTableDataSource(newArray)
    } 
  
    this.totalCounts()
  };

  checkIfDomotzIsEnabled = (dhui, item) => {
    const arr = dhui.split('/');
    arr.pop();
    // console.log(item.iot_devices_domotz);
    
    return item.domotzEnabled ? arr.join('/') + '/' + item.iot_devices_domotz + '/' + item.spaces_domotz : dhui + '/' + item.spaces;
  };

  getDomotzAccountByCompanyId = (company_id) => {
    return new Promise((resolve) => {
      this.Auth.getDomotzAccountByCompanyId(company_id).subscribe((data: any) => {
        const response = data.enabled === 1;
        resolve(response);
      });
    });
  };

  totalCounts() {
    this.videoTotal = 0;
    this.audioTotal = 0;
    this.siteTotal = 0;
    this.huddleTotal = 0;
    this.userTotal = 0;
    this.iotTotal = 0;
    this.spacesTotal = 0;

    this.dataSource?.filteredData.forEach((d) => {
      this.videoTotal += parseInt(d.video_devices, 10);
      this.audioTotal += parseInt(d.audio_devices, 10);
      this.iotTotal += d.domotzEnabled
        ? parseInt(d.iot_devices_domotz, 10)
        : parseInt(d.iot_devices, 10);
      this.huddleTotal += parseInt(d.huddle_rooms, 10);
      this.userTotal += parseInt(d.vip_users, 10);
      this.siteTotal += parseInt(d.sites, 10);
      this.spacesTotal += d.domotzEnabled
        ? parseInt(d.spaces_domotz, 10)
        : parseInt(d.spaces, 10);
    });
  }

  searchData = (key) => {
    this.dataSource.filter = key.trim().toLowerCase();
    this.totalCounts()
  };

  openLink = () => {
    const { loginType, company_id } = this.userInfo.getInfo();
    if (this.label === 'trial') {
      this.Auth.getTrialExportDownload(loginType, company_id).subscribe((data) => {
        this.downLoadFile(data, 'application/ms-excel');
      });
    }

    else if (this.label === 'subscription') {
      this.Auth.getSubscriptionExportDownload(loginType, company_id).subscribe((data) => {
        this.downLoadFile(data, 'application/ms-excel');
      });
    }

    else {
      this.Auth.getInactiveExportDownload(loginType, company_id).subscribe((data) => {
        this.downLoadFile(data, 'application/ms-excel');
      });
    }
  };

  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'ReportDownload.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // var pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert('Please disable your Pop-up blocker and try again.');
    // }
  }
}
