import apiServicePathBuilder from './apiServicePathBuilder';

export const environment = {
  production: true,
  dev_server: false,
  apiServicePathBuilder: apiServicePathBuilder({
    apiUrl: 'https://videlio-api.visibility.one',
  }),
  config: {
    tenantName: 'Videlio',
    serverUrl: 'https://fe-videlio.visibility.one',
    mainUrl: 'https://dashboard.videlio.com',
    webhookUrl: null,
    showPoweredBy: false,
    twilioServiceId: 'VA0984ed7bcdf80fb06fbdeb7b1f21c130',
    enable2FAFeature: false,
    domotzIntegration: false,
    isShowTeleportivity: false,
    superAdminCompanyId: 36,
    links: {
      terms: 'https://www.klood.io/conditions-generales-de-vente/',
    },
    dynamicImage: {
      loginImage: '../assets/img/videlio_img/loginImage.png',
      dashboardLogo: '../assets/img/videlio_img/dashboardLogo.png',
      bgImage: '/assets/img/videlio_img/bgImage.jpg',
      loader: '../assets/img/gbl_img/loader.gif',
      collectorImg: '../assets/img/videlio_img/collector_icon.png',
      macCollector: '../assets/img/videlio_img/maccollector_icon.png',
      pluginImg: '../assets/img/videlio_img/plugin_icon.png',
      desktopPluginImg: '../assets/img/videlio_img/desktop_plugin_icon.png',
    },
    dynamicText: {
      dTerms: 'Videlio terms and conditions',
      dLoginTitle: 'admin view',
      dashboardHeader: 'Super Admin Dashboard',
      dCollector: 'Collector',
      dPlugin: 'Plugin',
      desktopPlugin: 'Desktop Plugin',
    },
  },
};
