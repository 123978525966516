import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { UserInfoService } from '../../services/user-info.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  setoverlay: string = '';
  showPoweredBy = environment.config.showPoweredBy;
  highlightNum: number = 1;
  reportTrialData: Array<any> = null;
  reportSubscriptionsData: Array<any> = null;
  reportInactiveData: Array<any> = null;
  // videoTotal = 0;
  // audioTotal = 0;
  // iotTotal = 0;
  // huddleTotal = 0;
  // userTotal = 0;
  // siteTotal = 0;
  domotzEnabled: boolean;

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userInfo: UserInfoService
  ) { }

  ngOnInit() {
    this.getTrialReport();
    this.getSubscriptionsReport();
    this.getInactiveReport();
    // this.getDomotzAccountByCompanyId();
  }
  async delay(ms: number) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
  }

  setHighlight(n) {
    this.highlightNum = n;
    // if (n === 1) {
    //   this.totalCounts(this.reportTrialData);
    // } else if (n === 2) {
    //   this.totalCounts(this.reportSubscriptionsData);
    // } else if (n === 3) {
    //   this.totalCounts(this.reportInactiveData);
    // }
  }

  getTrialReport() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    // this.reportTrialData = [];
    const { loginType, company_id } = this.userInfo.getInfo();
    this.Auth.getReportTrials(loginType, company_id).subscribe((data) => {
      this.reportTrialData = data['response'];
      this.delay(300).then((any) => {
        this.setHighlight(1);
      });
      for (let i = 0; i < this.reportTrialData.length; i++) {
        this.reportTrialData[i].DHUAI =
          this.reportTrialData[i].video_devices +
          '/' +
          this.reportTrialData[i].huddle_rooms +
          '/' +
          this.reportTrialData[i].vip_users +
          '/' +
          this.reportTrialData[i].audio_devices +
          '/' +
          this.reportTrialData[i].iot_devices
          '/' +
          this.reportTrialData[i].spaces;
          
          
          
          // console.log('this.reportTrialData[i].video_devices: ',  this.reportTrialData[i].video_devices)
          // console.log('this.reportTrialData[i].huddle_rooms: ',  this.reportTrialData[i].huddle_rooms)
          // console.log('this.reportTrialData[i].vip_users: ',  this.reportTrialData[i].vip_users)
          // console.log('this.reportTrialData[i].audio_devices: ',  this.reportTrialData[i].audio_devices)
          // console.log('this.reportTrialData[i].iot_devices: ',  this.reportTrialData[i].iot_devices)
          // console.log('this.reportTrialData[i].spaces: ',  this.reportTrialData[i].spaces)
          
          
          // console.log('---------------------------')
          // console.log('---------------------------')
      }
    });
  }

  getSubscriptionsReport() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    // this.reportSubscriptionsData = [];
    const { loginType, company_id } = this.userInfo.getInfo();
    this.Auth.getReportSubscriptions(loginType, company_id).subscribe((data) => {
      this.reportSubscriptionsData = data['response'];
      for (let i = 0; i < this.reportSubscriptionsData.length; i++) {
        this.reportSubscriptionsData[i].DHUAI =
          this.reportSubscriptionsData[i].video_devices +
          '/' +
          this.reportSubscriptionsData[i].huddle_rooms +
          '/' +
          this.reportSubscriptionsData[i].vip_users +
          '/' +
          this.reportSubscriptionsData[i].audio_devices +
          '/' +
          this.reportSubscriptionsData[i].iot_devices;
      }
    });
  }

  getInactiveReport() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    // this.reportInactiveData = [];
    const { loginType, company_id } = this.userInfo.getInfo();
    this.Auth.getReportInactive(loginType, company_id).subscribe((data) => {
      this.reportInactiveData = data['response'];
      for (let i = 0; i < this.reportInactiveData.length; i++) {
        this.reportInactiveData[i].DHUAI =
          this.reportInactiveData[i].video_devices +
          '/' +
          this.reportInactiveData[i].huddle_rooms +
          '/' +
          this.reportInactiveData[i].vip_users +
          '/' +
          this.reportInactiveData[i].audio_devices +
          '/' +
          this.reportInactiveData[i].iot_devices;
      }
    });
  }

  // totalCounts(data) {
  //   this.videoTotal = 0;
  //   this.audioTotal = 0;
  //   this.siteTotal = 0;
  //   this.huddleTotal = 0;
  //   this.userTotal = 0;
  //   this.iotTotal = 0;

  //   data.forEach((d) => {
  //     this.videoTotal += parseInt(d.video_devices, 10);
  //     this.audioTotal += parseInt(d.audio_devices, 10);
  //     this.iotTotal += this.domotzEnabled
  //       ? parseInt(d.iot_devices_domotz, 10)
  //       : parseInt(d.iot_devices, 10);
  //     this.huddleTotal += parseInt(d.huddle_rooms, 10);
  //     this.userTotal += parseInt(d.vip_users, 10);
  //     this.siteTotal += parseInt(d.sites, 10);
  //   });
  // }

  // openLink = (tabNumber) => {
  //   const { loginType, company_id } = this.userInfo.getInfo();
  //   if (tabNumber == 1) {
  //     this.Auth.getTrialExportDownload(loginType, company_id).subscribe((data) => {
  //       this.downLoadFile(data, 'application/ms-excel');
  //     });
  //   }

  //   if (tabNumber == 2) {
  //     this.Auth.getSubscriptionExportDownload(loginType, company_id).subscribe((data) => {
  //       this.downLoadFile(data, 'application/ms-excel');
  //     });
  //   }

  //   if (tabNumber === 3) {
  //     this.Auth.getInactiveExportDownload(loginType, company_id).subscribe((data) => {
  //       this.downLoadFile(data, 'application/ms-excel');
  //     });
  //   }
  // };

  // downLoadFile(data: any, type: string) {
  //   var blob = new Blob([data], { type: 'text/csv' });
  //   var url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.setAttribute('hidden', '');
  //   a.setAttribute('href', url);
  //   a.setAttribute('download', 'ReportDownload.csv');
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   // var pwa = window.open(url);
  //   // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
  //   //   alert('Please disable your Pop-up blocker and try again.');
  //   // }
  // }
}
